/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable prettier/prettier */
import {useAtom} from 'jotai'
import Image from 'next/image'
import Script from 'next/script'
import {useEffect, useRef} from 'react'
import {videoAtom} from '../../store'

interface IVturb {
    player: string | undefined
    video: string | undefined
    vertical?: boolean
    iframe?: boolean
    full?: boolean
    abTest?: boolean
}

export default function VTurb({
                                  player,
                                  video,
                                  vertical = false,
                                  iframe = false,
                                  full = false,
                                  abTest = false,
                              }: IVturb) {
    {
        full &&
        useEffect(() => {
            let attempts = 0
            const whatchEvents = () => {

                if (
                    // @ts-ignore
                    typeof window.smartplayer === 'undefined' ||
                    !(
                        // @ts-ignore
                        window.smartplayer.instances &&
                        // @ts-ignore
                        window.smartplayer.instances.length
                    )
                ) {
                    if (attempts >= 10) return
                    attempts += 1
                    return setTimeout(() => {
                        whatchEvents()
                    }, 1000)
                }

                // inica o fullscreen quando der play
                // @ts-ignore
                window.smartplayer.instances[0].on('play', () => {
                    // @ts-ignore
                    if (window.smartplayer.instances[0].smartAutoPlay) {
                        return
                    }
                    // @ts-ignore
                    window.smartplayer.instances[0].fullScreen.request()
                })

                // cancela o fullscreen quando der pause
                // @ts-ignore
                window.smartplayer.instances[0].on('pause', () => {
                    // @ts-ignore
                    window.smartplayer.instances[0].fullScreen.cancel()
                })
            }

            whatchEvents()
        }, [])
    }

    //use effect to wait smartplayer element id to be created
    useEffect(() => {
        const interval = setInterval(() => {
            if (document.getElementById(`vid_${video}`)) {
                clearInterval(interval)
                const smart = document.getElementById('smartplayer')
                if (smart) {
                    document.getElementById(`vid_${video}`)?.appendChild(smart)
                }
            }
        }, 200)
        return () => clearInterval(interval)
    }, [video])

    return iframe ? (
        <div className={`relative`}>
            <iframe
                src={`https://scripts.converteai.net/${player}/players/${video}/embed.html`}
                id={`ifr_${video}`}
                className='relative top-0 left-0 w-full h-full rounded-xl aspect-[6/10.5]'
                referrerPolicy='origin'
                title='Video Player'
            ></iframe>
        </div>
    ) : (
        <div className={`w-full h-auto relative flex rounded-xl ${vertical ? 'max-w-[80%]' : ''}`}>
            <div
                id={`vid_${video}`}
                className={`relative w-full rounded-xl overflow-hidden box-glow grid place-items-center`}
            >
                <Image
                    id={`thumb_${video}`}
                    layout='fill'
                    src={`https://images.converteai.net/${player}/players/${video}/thumbnail.jpg`}
                    className={`absolute rounded-xl top-0 left-0 w-full h-full object-cover block`}
                    alt='Video Thumbnail'
                />

                <div
                    id={`backdrop_${player}`}
                    className='absolute rounded-xl top-0 left-0 w-full h-full bg-black opacity-50 blackdrop-blur-sm'
                    style={{WebkitBackdropFilter: 'blur(5px)'}}
                ></div>
            </div>
            {video && player && (
                <Script type='text/javascript' id={'scr_' + video}>
                    {`
                        const s=document.createElement('script');
                        s.src="https://scripts.converteai.net/${player}/${abTest ? 'ab-test' : 'players'}/${video}/player.js"
                        s.async=!0
                        document.head.appendChild(s);
                    `}
                </Script>
            )}
        </div>
    )
}

// @ts-ignore
export const HiddenElements = ({children, seconds}) => {
    const [state, setState] = useAtom(videoAtom)
    const {showElements, showModal} = state

    const showModalRef = useRef(showModal)

    useEffect(() => {
        showModalRef.current = showModal

        // @ts-ignore
        if (typeof window.smartplayer !== 'undefined' && showModalRef.current === 0) {
            // play video
            // @ts-ignore
            window.smartplayer.instances[0].play()
        }

    }, [showModal])

    useEffect(() => {
        const alreadyDisplayedKey = `@VPalreadyElsDisplayed${seconds}`

        let attempts = 0

        const showHiddenElements = () => {
            setState({showElements: true})
            localStorage.setItem(alreadyDisplayedKey, String(true))
        }

        const startWatchVideoProgress = () => {
            if (// @ts-ignore
                typeof window.smartplayer === 'undefined' ||
                !(// @ts-ignore
                    window.smartplayer.instances &&
                    // @ts-ignore
                    window.smartplayer.instances.length
                )
            ) {
                if (attempts >= 10) return
                attempts += 1
                return setTimeout(() => {
                    startWatchVideoProgress()
                }, 1000)
            }

            // @ts-ignore
            window.smartplayer.instances[0].on('timeupdate', () => {
                // @ts-ignore
                const currentTime = window.smartplayer.instances[0].video.currentTime
                // @ts-ignore
                if (typeof window.smartplayer !== 'undefined'
                    && showModalRef.current !== 0
                    && currentTime > !!showModalRef.current
                ) {
                    //pause video
                    // @ts-ignore
                    window.smartplayer.instances[0].pause()
                    const modal = document.getElementById('early-checkout') as HTMLInputElement
                    modal.checked = true
                }

                // @ts-ignore
                if (showElements || window.smartplayer.instances[0].smartAutoPlay) {
                    return
                }
                // @ts-ignore
                if (window.smartplayer.instances[0].video.currentTime < seconds) {
                    return
                }
                showHiddenElements()
            })
        }

        const alreadyElsDisplayed = localStorage.getItem(alreadyDisplayedKey)

        if (typeof window !== 'undefined') {
            if (alreadyElsDisplayed === 'true') {
                setTimeout(() => {
                    showHiddenElements()
                }, 100)
            } else {
                startWatchVideoProgress()
            }
        }

    }, [seconds, setState, showElements])

    return showElements && children
}
