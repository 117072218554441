/* eslint-disable prettier/prettier */
import { BlurImage } from '../components'
import Box from '../components/box'
import CustomLink from '../components/custom-link'

const Header = () => {
    return (
        <div className=''>
            <Box
                className='flex justify-between !py-2'
                classesForBackground='bg-black/20 backdrop-blur fixed z-[99999] top-0 py-2'
            >
                <BlurImage src='/logo.webp' width={90} height={40} alt='Logo' />
                <CustomLink
                    text='garanta seu ingresso'
                    className='text-xs'
                    hasHoverEffect={false}
                />
            </Box>
        </div>
    )

}


export default Header
