export const whatWillLearnMoc = {
    title: 'O que você vai aprender no Game Change Empreenda?',
    items: [
        'Marketing prático',
        'Estrutura Comercial replicável',
        'Criação de produtos milionários',
        'Contratação e Gestão Home Office que funciona',
        'Tecnologia que diminui dor de cabeça e facilita escala',
    ],
    buttonText: 'Quero Meu Ingresso!',
}
