/* eslint-disable prettier/prettier */
import React, { FC } from 'react'

interface ITitleProps extends React.HTMLAttributes<HTMLHeadingElement> {
    text: string
}

const Title: FC<ITitleProps> = ({ text, className, ...rest }) => {
    return (
        <h2 className={`
        font-bold 
        text-center 
        text-white 
        uppercase 
        text-2xl 
        md:text-3xl 
        max-w-5xl 
        mx-auto 
        flex 
        flex-col 
        gap-2
        pb-[64px]
        md:pb-[112px]
        ${className}
        `}
            {...{ ...rest }}
        >
            {text}
        </h2>
    )
}

export default Title