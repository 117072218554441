/* eslint-disable prettier/prettier */
import React, { FC } from 'react'

interface ICustomButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    text: string
    hasHoverEffect?: boolean
}

const CustomButton: FC<ICustomButtonProps> = ({ text, className, hasHoverEffect = true, ...rest }) => {
    const hoverEffect = hasHoverEffect && 'hover:scale-[1.1] transition ease-in-out duration-300'
    return (
        <button className={`z-10 flex items-center justify-center uppercase px-6 py-3 bg-300 bg-button-gradient animate-button-animation font-bold text-white rounded-[4px] ${hoverEffect} ${className}`} {...rest}>{text}</button>
    )
}

export default CustomButton