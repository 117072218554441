import { CarLocation, Location, Phone } from '../components'

const hotels = [
  {
    id: 1,
    image: '/hotels/ibis-interlagos.webp',
    stars: 3,
    title: 'BIS INTERLAGOS',
    infos: [
      {
        icon: <Phone size={1.25} />,
        text: '(11) 5633-4800'
      },
      {
        icon: <Location size={1.25} />,
        text: 'Av. Interlagos, 2215 - Vila Constancia, São Paulo - SP, 04661-200'
      },
      {
        icon: <CarLocation size={1.25} />,
        text: '10 min de carro ate o local do evento.'
      }
    ]
  },
  {
    id: 2,
    image: '/hotels/mercure-sao-paulo.webp',
    stars: 4,
    title: 'MERCURE SÃO PAULO NAÇÕES UNIDAS',
    infos: [
      {
        icon: <Phone size={1.25} />,
        text: '(11) 5188-3855'
      },
      {
        icon: <Location size={1.25} />,
        text: 'R. Professor Manoelito de Ornellas, 104 - Chácara Santo Antônio, São Paulo - SP, 04719-040'
      },
      {
        icon: <CarLocation size={1.25} />,
        text: '10 min de carros ate o local do evento.'
      }
    ]
  },
  {
    id: 3,
    image: '/hotels/blueTree-verbo-divino.webp',
    stars: 4,
    title: 'BLUE TREE PREMIUM VERBO DIVINO',
    infos: [
      {
        icon: <Phone size={1.25} />,
        text: '(11) 5683-4600'
      },
      {
        icon: <Location size={1.25} />,
        text: 'R. Verbo Divino, 1323 - Chácara Santo Antônio, São Paulo - SP, 04719-002'
      },
      {
        icon: <CarLocation size={1.25} />,
        text: '16 min de carro ate o local do evento.'
      }
    ]
  },
  {
    id: 4,
    image: '/hotels/intercity-nacoes-unidas.webp',
    stars: 4,
    title: 'HOTEL INTERCITY NAÇÕES UNIDAS',
    infos: [
      {
        icon: <Phone size={1.25} />,
        text: '(11) 5189-6555'
      },
      {
        icon: <Location size={1.25} />,
        text: 'R. Fernandes Moreira, 1371 - Chácara Santo Antônio (Zona Sul), São Paulo - SP, 04716-003'
      },
      {
        icon: <CarLocation size={1.25} />,
        text: '17 min de carro do local do evento.'
      }
    ]
  }
]

export const hotelsMoc = {
  title: 'Hotéis próximo do Evento',
  hotels
}
