import { atom } from 'jotai'
import { IVideoStore } from '../types/video'

export const videoDefaultValue: IVideoStore = {
    showElements: false,
    showModal: null,
}

const videoDefaultAtom = atom<IVideoStore>(videoDefaultValue)

export const videoAtom = atom(
    (get) => get(videoDefaultAtom),
    (get, set, update: Partial<IVideoStore>) => {
        set(videoDefaultAtom, { ...get(videoDefaultAtom), ...update })
    }
)
