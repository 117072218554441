import { Whatsapp } from '@/components'
import Link from 'next/link'
import React, { FC } from 'react'

interface ITalkToUsProps {
    whatsRedirect: string
}


const TalkToUs: FC<ITalkToUsProps> = ({ whatsRedirect }) => {
    return (
        <div
            className='
            w-full
            flex
            items-center
            justify-center
            '
        >
            <Link
                href={whatsRedirect}
                target='_blank'
                className='
                flex 
                items-center 
                justify-center
                gap-4
                bg-[#259c1e] 
                mt-14
                py-4 
                px-12 
                rounded-full 
                text-white 
                font-bold 
                text-lg
                z-[99999]
                text-[32px]
                justify-self-center
                self-center
            '
            >
                <Whatsapp size={2.5} />
                <span>
                    Tem alguma dúvida?
                </span>
            </Link>
        </div>
    )
}

export default TalkToUs