/* eslint-disable prettier/prettier */
import { FC } from 'react'
import { Blur, BlurImage, Box, Title } from '../components'
import { IDiscoveryProps } from '../types/home/discovery'

const Discovery: FC<IDiscoveryProps> = ({ title, text }) => {
    return (
        <Box className='pb-0 relative'
        >
            <Title
                text={title}
            />
            <Blur direction='left-bottom' />
            <Blur direction='right-top' />

            <div className='flex flex-col gap-8 items-center justify-center lg:flex-row'>
                <div className='flex-1'>
                    <BlurImage src='/luiz-gregatti.webp' width={600} height={600} alt='Luiz Gregatti' />
                </div>
                <div className='flex-1 flex items-center justify-center'>
                    <p
                        className='text-white font-bold text-2xl text-center z-10'
                    >
                        {text}
                    </p>
                </div>
            </div>
        </Box>
    )
}

export default Discovery