/* eslint-disable prettier/prettier */
import { FC } from 'react'
import { Blur, Box, Brand, Title } from '../components'
import { ISponsorsProps } from '../types/home/sponsors'

const Sponsors: FC<ISponsorsProps> = ({ title, images }) => {
    return (
        <Box
            className='relative flex flex-col items-center justify-center lg:h-[70vh]'
        >
            <Blur direction='left-bottom' />
            <Title
                text={title}
            />
            <div className='flex justify-center items-center gap-8 flex-wrap max-w-[960px]'>
                {images.map((image, idx) => (
                    <Brand key={idx} title={String(image)} image={image.src} background={image.background} />
                ))}
            </div>
        </Box>
    )
}

export default Sponsors