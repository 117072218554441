/* eslint-disable prettier/prettier */
import { FC, useEffect, useState } from 'react';
import { Blur, Box, Slide, Title } from '../components';
import { useMediaQuery } from '../hooks';
import { WidthType } from '../types/common';
import { IWhatAwaitsYouProps } from '../types/home/what-awaits-you';



const WhatAwaitsYou: FC<IWhatAwaitsYouProps> = ({ title, images }) => {

    const mobile = useMediaQuery('(max-width:767px)')
    const tablet = useMediaQuery('(min-width:768px)')
    const laptop = useMediaQuery('(min-width:1024px)')
    const desktop = useMediaQuery('(min-width:1300px)')
    const [width, setWidth] = useState<WidthType>('tablet')

    useEffect(() => {
        if (mobile) setWidth('mobile')
        if (tablet) setWidth('tablet')
        if (laptop) setWidth('laptop')
        if (desktop) setWidth('desktop')
    }, [mobile, tablet, laptop, desktop])

    const imageConfig = {
        mobile: '300px',
        tablet: '524px',
        laptop: '524px',
        desktop: '724px'
    }

    return (
        <Box className='relative'
        >
            <Blur direction='left-top' />
            <Blur direction='right-bottom' />
            <Title
                text={title}
            />
            <div className={`flex mx-auto justify-center max-w-[1000px] h-[${imageConfig[width]}]`}>
                <Slide {...{ images }} />
            </div>
        </Box>
    )
}

export default WhatAwaitsYou