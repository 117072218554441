/* eslint-disable prettier/prettier */
import { FC } from 'react'
import { Box, CustomLink } from '../components'
import VTurb from '../components/Player/VTub'
import { IMainBanner } from '../types/home/main-banner'
import { Container } from './last-event/styles'
import { useMediaQuery } from '@/hooks'
import Link from 'next/link'

const MainBanner: FC<IMainBanner> = ({ infoList, linkText, mainTitle, nameEvent, subTitle, warning, videoId }) => {
  const isDesktop = useMediaQuery('(min-width: 1024px)')
  const hoverEffect = 'hover:scale-[1.1] transition ease-in-out duration-300'

  return (
    <Box classesForBackground='bg-[url("/background-main-banner.webp")] bg-cover bg-no-repeat lg:h-screen' className='pt-[150px] flex gap-12 h-full flex-col lg:flex-row justify-center items-center'>
      <div className='text-white justify-center flex flex-col gap-6 relative'>
        <h2 className='text-2xl text-center font-bold lg:text-left lg:w-[35ch]'>{mainTitle.text}</h2>
        {!isDesktop && (
          <Container className='w-full rounded-xl overflow-hidden box-glow'>
            <VTurb video={'6645280f479307000acade1f'} player={'99583553-0c7c-40d5-b819-534dcd7867b9'} />
          </Container>
        )}
        <h2 className='text-base text-center lg:text-left lg:w-[50ch]'>{subTitle.text}</h2>
        <h3 className='text-base text-center font-bold uppercase lg:text-left lg:w-[45ch]'>{warning.text}</h3>
        <ul className='flex flex-col gap-2'>
          {infoList.map(info => (
            <li key={info.id} className='flex gap-4 items-start'>
              {info.icon}
              <p className='flex flex-col text-[14px]'>
                <span className='text-gray-300'>{info.title}</span>
                <span className='font-medium'>
                  {info.description.map((text, idx) => {
                    return (
                      <span key={idx}>
                        {text}
                        {idx < info.description.length - 1 && <br />}
                      </span>
                    )
                  })}
                </span>
              </p>
            </li>
          ))}
        </ul>

        <Link
          className={`
            border-1
            box-orange-glow
            z-10 
            flex 
            items-center 
            justify-center 
            uppercase
            bg-300 
            animate-button-animation 
            font-bold 
            rounded-[4px] 
            bg-green-gradient 
            text-base 
            py-4 
            px-5 
            lg:w-[350px]
            text-[#152e00]
            ${hoverEffect}
        `
          }
          href='#experience'
        >
          {linkText.text}
        </Link>


      </div>
      {isDesktop && (
        <Container className='w-full rounded-xl overflow-hidden box-glow'>
          <VTurb video={'6645280f479307000acade1f'} player={'99583553-0c7c-40d5-b819-534dcd7867b9'} />
        </Container>
      )}
    </Box>
  )
}

export default MainBanner
