/* eslint-disable prettier/prettier */
import { modalAtom } from '@/store/modal'
import { useAtom } from 'jotai'
import { Blocked, Blur, Box, Card, Checked, Title } from '../components'
import { checkoutAtom } from '@/store'

const Experience = () => {
    const [_, setModal] = useAtom(modalAtom)
    const [__, setCheckout] = useAtom(checkoutAtom)

    const cards = [
        {
            title: 'Black 1.997',
            list: [
                { id: "2_dias", icon: <Checked size={1.25} />, description: "2 dias de evento" },
                { id: "welcome_kit", icon: <Checked size={1.25} />, description: "Welcome Kit Black (Moleskine, Caneta, Squeeze, Caixa)" },
                { id: "coffee_break", icon: <Checked size={1.25} />, description: "Coffee Break, água e café" },
                { id: "open_cerveja_energetico", icon: <Checked size={1.25} />, description: "Open de Cerveja e Energético" },
                { id: "pdf_slides", icon: <Checked size={1.25} />, description: "PDFs dos Slides das palestras" },
                { id: "gravacao_imersao", icon: <Checked size={1.25} />, description: "Gravação de toda Imersão" },
                { id: "jantar_sabado_domingo", icon: <Checked size={1.25} />, description: "Jantar sábado e domingo" },
                { id: "acesso_area_vip", icon: <Checked size={1.25} />, description: "Acesso a área Vip do Evento (primeiros lugares)" },
                { id: "networking_diretoria_grupo_fly_freedom_mastermind", icon: <Checked size={1.25} />, description: "Networking com Diretoria do Grupo Fly e Membros do Freedom Mastermind" },
                { id: "grupo_whatsapp_networking", icon: <Checked size={1.25} />, description: "Grupo de WhatsApp aberto após evento para Networking" },
                { id: "30_minutos_individual_luiz_anderson_online", icon: <Checked size={1.25} />, description: "30 minutos individual com Luiz ou Anderson online" }
            ],
            buttonText: 'Quero Meu Ingresso!',
            onClick: () => {
                setModal({ open: true })
                setCheckout({
                    checkoutLink: 'https://go.rendacommilhas.com.br/pay/gce-oferta-black-1997-1p',
                    value: 1697,
                    page: 'Black',
                    eventId: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
                })
            },
        },
        {
            title: 'Platinum 697',
            list: [
                { id: "2_dias", icon: <Checked size={1.25} />, description: "2 dias de evento"},
                { id: "welcome_kit", icon: <Checked size={1.25} />, description: "Welcome Kit Platinum (Moleskine e Caneta)" },
                { id: "coffee_break", icon: <Checked size={1.25} />, description: "Coffee Break, água e café" },
                { id: "open_cerveja_energetico", icon: <Blocked size={1.25} />, description: "Open de Cerveja e Energético", blocked: true },
                { id: "pdf_slides", icon: <Blocked size={1.25} />, description: "PDFs dos Slides das palestras", blocked: true },
                { id: "gravacao_imersao", icon: <Blocked size={1.25} />, description: "Gravação de toda Imersão", blocked: true },
                { id: "jantar_sabado_domingo", icon: <Blocked size={1.25} />, description: "Jantar sábado e domingo", blocked: true },
                { id: "acesso_area_vip", icon: <Blocked size={1.25} />, description: "Acesso a área Vip do Evento (primeiros lugares)", blocked: true },
                { id: "networking_diretoria_grupo_fly_freedom_mastermind", icon: <Blocked size={1.25} />, description: "Networking com Diretoria do Grupo Fly e Membros do Freedom Mastermind", blocked: true },
                { id: "grupo_whatsapp_networking", icon: <Blocked size={1.25} />, description: "Grupo de WhatsApp aberto após evento para Networking", blocked: true },
                { id: "30_minutos_individual_luiz_anderson_online", icon: <Blocked size={1.25} />, description: "30 minutos individual com Luiz ou Anderson online", blocked: true }
            ],
            buttonText: 'Quero Meu Ingresso!',
            onClick: () => {
                setModal({ open: true })
                setCheckout({
                    checkoutLink: 'https://go.rendacommilhas.com.br/campaign/gce-5ed-20-plat-1p-luiz?coupon=DLP300',
                    value: 697,
                    page: 'Platinum',
                    eventId: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
                })
            },
        },

    ]

    return (
        <Box
            className='flex flex-col relative'
            id='experience'
        >
            <Blur direction='left-mid' />
            <Title
                text='Seleciona a sua experiência:
            '
            />
            <div className='flex flex-col xl:flex-row gap-4 items-center justify-center h-full'>
                {cards.map(card => <Card key={card.title} {...{ ...card }} />)}
            </div>
        </Box>
    )
}

export default Experience