import Image, { ImageProps } from 'next/image'
import { FC, useState } from 'react'

function cn(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

interface BlurImageProps extends ImageProps { }

const BlurImage: FC<BlurImageProps> = ({ src, className, ...props }) => {
    const [isLoading, setLoading] = useState(true)
    return (
        <Image
            {...props}
            src={src}
            quality={75}
            className={cn(
                `duration-100 ease-in-out ${className}`,
                isLoading ? 'blur-2xl scale-110' : 'blur-0 scale-100'
            )}
            onLoad={() => setLoading(false)}
            alt={props.alt}
            priority={true}  // Usando priority que é suportado pelo Next.js
        />
    )
}

export default BlurImage