import { atom } from 'jotai'
import { IModalStore } from '../types/modal'

export const modalDefaultValue: IModalStore = {
  open: false,
}

const modalDefaultAtom = atom<IModalStore>(modalDefaultValue)

export const modalAtom = atom(
  (get) => get(modalDefaultAtom),
  (get, set, update: Partial<IModalStore>) => {
    set(modalDefaultAtom, { ...get(modalDefaultAtom), ...update })
  }
)
