/* eslint-disable prettier/prettier */
import { RefObject, useEffect, useState } from "react"

interface IUserComponentVisible {
    component: RefObject<HTMLDivElement>
}

const useComponentVisible = ({ component }: IUserComponentVisible) => {
    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true)
                }
                else {
                    setIsVisible(false)
                }
            })
        }, {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        })
        if (component.current) {
            observer.observe(component.current)
        }
        return () => {
            if (component.current) {
                observer.unobserve(component.current)
            }
        }
    }, [component])
    return { isVisible }
}

export default useComponentVisible