/* eslint-disable prettier/prettier */
import { FC } from 'react'

interface IBlurProps {
    direction?: 'left-top' | 'left-mid' | 'left-bottom' | 'right-top' | 'right-mid' | 'right-bottom'
}

const Blur: FC<IBlurProps> = ({ direction = 'right-mid' }) => {
    const commumConfig = 'rounded-full blur-3xl hidden absolute pointer-events-none lg:inline-flex'
    const blurConfig = {
        'left-top': {
            primary: 'opacity-[.3]  left-0 top-[10px] w-[440px] h-[440px]',
            secondary: 'opacity-[.2] left-[-0px] top-[55px] w-[640px] h-[640px]',
        },
        'left-mid': {
            primary: 'opacity-[.2] left-0 top-[10px] w-[840px] h-[840px]',
            secondary: 'opacity-[.1] left-0 top-[5px] w-[640px] h-[640px]',
        },
        'left-bottom': {
            primary: 'opacity-[.3] left-0 bottom-[10px] w-[440px] h-[440px]',
            secondary: 'opacity-[.2] left-[-0px] bottom-[55px] w-[640px] h-[640px]',
        },
        'right-top': {
            primary: 'opacity-[.3] right-0 top-[10px] w-[440px] h-[440px]',
            secondary: 'opacity-[.2] right-[-0px] top-[55px] w-[640px] h-[640px]',
        },
        'right-mid': {
            primary: 'opacity-[.2] right-0 top-[10px] w-[840px] h-[840px]',
            secondary: 'opacity-[.1] right-0 top-[5px] w-[640px] h-[640px]',
        },
        'right-bottom': {
            primary: 'opacity-[.3] right-0 bottom-[10px] w-[440px] h-[440px]',
            secondary: 'opacity-[.2] right-[-0px] bottom-[55px] w-[640px] h-[640px]',
        },
    }
    return <>
        <span className={`${commumConfig} bg-warning ${blurConfig[direction].primary}`} />
        <span className={`${commumConfig} bg-primary ${blurConfig[direction].secondary}`} />
    </>
}

export default Blur
