export const sponsorsMoc = {
    title: 'Patrocinadores',
    images: [
      { src: '/sponsors/american-travel.webp', background: 'bg-white' },
      { src: '/sponsors/cora.webp', background: 'bg-[#FE3E6C]' },
      { src: '/sponsors/frontur.webp', background: 'bg-white' },
      { src: '/sponsors/hero-seguros.webp', background: 'bg-white' },
      { src: '/sponsors/pay-turismo.webp', background: 'bg-[#005F91]' },
    ],
  }
  