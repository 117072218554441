export { default as Depoiments } from './depoiments'
export { default as Discovery } from './discovery'
export { default as Experience } from './experience'
export { default as Footer } from './footer'
export { default as Group } from './group'
export { default as Header } from './header'
export { default as Hotels } from './hotels'
export { default as LastEvent } from './last-event'
export { default as MainBanner } from './main-banner'
export { default as Sponsors } from './sponsors'
export { default as WhatAwaitsYou } from './what-awaits-you'
export { default as WhatWillLearn } from './what-will-learn'
export { default as TalkToUs } from './talk-to-us'
