import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    & .swiper-pagination-fraction {
        color: white;
        background: black;
        width: 60px;
        left: 50%;
        transform: translateX(-50%);
    }
`
