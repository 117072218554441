import styled, {keyframes} from 'styled-components'

const slideUp = keyframes`
    from {
        transform: translate(-50%, 5%);

    }
    to {
        opacity: 1;
        transform: translate(-50%, 0);
    }
`

const slideDown = keyframes`
    from {

        transform: translate(-50%, -40%);
    }
    to {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
`

const fadeIn = keyframes`
    0% {
        transform: translateY(50px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
`

const fadeInModal = keyframes`
    0% {
        opacity: 0;
        transform: translate(-50%, -45%);
    }
    100% {
        opacity: 1;
        bottom: initial;
        transform: translate(-50%, -50%);
    }
`

export const Backdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(2px);
    z-index: 100000;
`

export const ModalTransactionWrapper = styled.div`
    position: fixed;
    width: 100%;
    background: #fff;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    z-index: 100000;
    bottom: 0;
    padding: 1.75rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    animation: ${fadeIn} 0.5s ease-in-out forwards;
    box-shadow: 0 0 2px #670CA9, 0 0 8px #670CA9;

    > div {
        position: relative;
    }

    h2 {
        color: #670CA9;
        font-size: 1.5rem;
        margin-bottom: 1rem;
        font-weight: bold;
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        > div {
            height: 62px;

            input {
                width: 100%;
                height: 60px;
                border-radius: 10px;
                padding: 0.5rem 1rem;
                background: #fff;
                margin-bottom: 0.25rem;
                box-shadow: 0 0 0 1px #CCCCCC;
                color: #454444;

                &::placeholder {
                    font-family: 'Montserrat', sans-serif;
                    color: #CCCCCC;
                }
            }

            p {
                color: #5c0600;
                font-size: 0.75rem;
                margin-left: 0.5rem;
            }
        }

        button {
            width: 100%;
            border-radius: 10px;
            padding: 0.5rem 1rem;
            background: #670CA9;
            color: #FFFFFF;
            font-weight: bold;
            cursor: pointer;
            transition: background-color 0.2s;
            height: 60px;
            
            &:hover {
                background-color: #ffd00c99;
            }

            &:disabled {
                background-color: #ffd00c66;
                cursor: not-allowed;
            }
        }
    }

    @media (min-width: 650px) {
        left: 50%;
        top: 50%;

        width: 500px;
        bottom: initial;
        border-radius: 6px;
        animation: ${fadeInModal} 0.5s ease-in-out forwards;
    }
`

export const CloseButton = styled.button`
    position: absolute;
    top: -0.75rem;
    right: -0.75rem;
    cursor: pointer;
    transition: color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;

    &::after,
    &::before {
        content: '';
        width: 1rem;
        height: 2px;
        background: #454444;
        position: absolute;
    }

    &::after {
        transform: rotate(45deg);
    }

    &::before {
        transform: rotate(-45deg);
    }
`
