/* eslint-disable prettier/prettier */
import React, { FC } from 'react'
import { Blur, Box, CustomButton, Title } from '../components'
import { IGroupProps } from '../types/home/group'

const Group: FC<IGroupProps> = ({ title, text, buttonText }) => {
    return (
        <Box className='flex flex-col items-center justify-center relative'>
            <Blur
                direction='right-bottom'
            />
            <Title
                text={title}
            />

            <p className='text-white z-10 text-center text-2xl font-bold max-w-[40ch]'>{text}</p>

            <CustomButton
                className='mt-12'
                text={buttonText}
            />
        </Box>
    )
}

export default Group