/* eslint-disable prettier/prettier */
import React, { FC } from 'react'
import { Whatsapp } from './icons'
import Link from 'next/link'

interface IWhastappButtonProps {
    whatsRedirect: string
}

const WhatsappButton: FC<IWhastappButtonProps> = ({ whatsRedirect }) => {
    return (
        <Link 
            href={whatsRedirect}
            target='_blank'
            className='
                fixed 
                animate-fade-in 
                bottom-4 
                flex 
                items-center 
                gap-2 
                bg-[#259c1e] 
                py-4 
                px-8 
                rounded-full 
                text-white 
                font-bold 
                text-lg 
                z-[99999]
            '
        >
            <Whatsapp size={1.5} />
            <span>
                Tem alguma dúvida?
            </span>
        </Link>
    )
}

export default WhatsappButton