/* eslint-disable prettier/prettier */
import React from 'react'

const Footer = () => {
    return (
        <footer className='text-center text-white py-20 px-4 bg-black'>
            <div className='max-w-[842px] mx-auto flex flex-col gap-8 text-base'>
                <p>Todos os direitos reservados - Game Change - 2024</p>
                <p>Fly Launch Ltda. CNPJ: 46.333.828/0001-62</p>
                <p className='text-xs'>Nenhuma informação contida neste deve ser interpretada como uma afirmação da obtenção de resultados. Qualquer referência ao desempenho passado ou potencial de uma estratégia abordada no conteúdo não é, e não deve ser interpretada como uma recomendação ou como garantia de qualquer resultado específico. Os resultados podem variar de pessoa para pessoa.</p>
            </div>
        </footer>
    )
}

export default Footer