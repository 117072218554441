/* eslint-disable prettier/prettier */
import { FC } from 'react'
import { Blur, BlurImage, Box, CustomLink, Title } from '../components'
import { IWhatWillLearnProps } from '../types/home/what-will-learn'

const WhatWillLearn: FC<IWhatWillLearnProps> = ({ title, items, buttonText }) => {
    return (
        <Box className=' relative'>
            <Blur />
            <Title
                className='text-center text-white !pb-8'
                text={title}
            />
            <div className='flex flex-col gap-8 md:gap-0 md:flex-row justify-center items-center'>
                <div className='flex-1 flex flex-col'>
                    <ol className='mb-12 flex flex-col gap-2'>
                        {items.map((item, index) => (
                            <li key={index} className='text-xl text-white'>- {item}</li>
                        ))}
                    </ol>

                    <CustomLink
                        text={buttonText}
                        className='w-full lg:w-[350px]'
                    />

                </div>
                <div className='relative flex-1'>
                    <BlurImage
                        src={'/phone.webp'}
                        alt={'Photo'}
                        className={'w-full z-20 transform transition-transform duration-500 hover:scale-110 max-w-2xl'}
                        width={400}
                        height={100}
                    />
                    <BlurImage
                        src={'/bracelet.webp'}
                        alt={'Photo'}
                        className={
                            'absolute -bottom-12 z-30 transform duration-500 transition-transform hover:scale-110 w-[100%] max-w-[35rem]'
                        }
                        width={400}
                        height={100}
                    />
                </div>
            </div>
        </Box >
    )
}

export default WhatWillLearn