/* eslint-disable prettier/prettier */
import Link from 'next/link'
import React, { FC } from 'react'

interface ICustomLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
    text: string
    href?: string
    hasHoverEffect?: boolean
}

const CustomLink: FC<ICustomLinkProps> = ({ text, href = '#experience', className, hasHoverEffect = true, ...rest }) => {
    const hoverEffect = hasHoverEffect && 'hover:scale-[1.1] transition ease-in-out duration-300'
    return (
        <Link 
            className={`z-10 flex items-center justify-center uppercase px-6 py-3 bg-300 bg-button-gradient animate-button-animation font-bold text-white rounded-[4px] ${hoverEffect} ${className}`}
            {...{ ...rest, href }}
        >{text}</Link>
    )
}

export default CustomLink