/* eslint-disable prettier/prettier */
import { FC } from 'react'
import { BlurImage } from '.'

interface IBrandProps {
    image: string
    title: string
    background: string
}

const Brand: FC<IBrandProps> = (props) => {
    return (
        <div className={`flex items-center justify-center z-10 w-36 h-36 rounded-full p-4 border border-[#C121E1] hover:scale-[1.1] transition ease-in-out duration-300 overflow-hidden ${props.background}`}>
            <BlurImage src={props.image} width={1000} height={1000} alt={props.title} />
        </div>
    )
}

export default Brand