/* eslint-disable prettier/prettier */
import {useAtom} from 'jotai'
import Script from 'next/script'
import {ModalForm, WhatsappButton} from '@/components'
import {mainBannerMoc} from '@/mocs'
import {depoimentsMoc} from '@/mocs/depoiments'
import {discoveryMoc} from '@/mocs/discovery'
import {groupMoc} from '@/mocs/group'
import {hotelsMoc} from '@/mocs/hotels'
import {sponsorsMoc} from '@/mocs/sponsors'
import {whatAwaitsYouMoc} from '@/mocs/what-awaits-you'
import {whatWillLearnMoc} from '@/mocs/what-will-learn'
import {
    Depoiments,
    Discovery,
    Experience,
    Footer,
    Group,
    Header,
    Hotels,
    MainBanner,
    Sponsors,
    TalkToUs,
    WhatAwaitsYou,
    WhatWillLearn
} from '@/shared'
import {checkoutAtom} from '@/store'

const NewPage = () => {
    const [state] = useAtom(checkoutAtom)

    return <>
        {process.env.NODE_ENV === 'production' && <Script
            id="facebook-pixel-page"
            strategy="afterInteractive"
        >
            {`fbq('init', '${process.env.NEXT_PUBLIC_FB_PIXEL_ID}');
        fbq('track', 'PageView', {}, {eventID: '${'PageView' + state.eventId}'});`}
        </Script>
        }


        <div className='bg-black'>
            <ModalForm/>
            <Header/>
            <MainBanner {...{...mainBannerMoc}} />
            <WhatsappButton whatsRedirect='https://andrinno.com/redirect/queue/157'/>

            <WhatWillLearn {...{...whatWillLearnMoc}} />
            <WhatAwaitsYou {...{...whatAwaitsYouMoc}} />
            <Experience/>
            <Depoiments {...{...depoimentsMoc}} />
            <Hotels {...{...hotelsMoc}} />
            <Group {...{...groupMoc}} />
            <Sponsors {...{...sponsorsMoc}} />
            <Discovery {...{...discoveryMoc}} />
            <TalkToUs whatsRedirect='https://andrinno.com/redirect/queue/157'/>
            <Footer/>
        </div>
    </>
}

export default NewPage