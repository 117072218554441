/* eslint-disable prettier/prettier */
import { FC } from 'react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { BlurImage } from '..'
import { Container } from './styles'

interface ISlideProps {
  images: string[]
}

const Slide: FC<ISlideProps> = ({ images }) => {
  return (
    <Container>
      <Swiper
        pagination={{
          type: 'fraction'
        }}
        modules={[Pagination, Navigation, Autoplay]}
        className='mySwiper rounded-2xl'
        autoplay={{
          delay: 2500,
          disableOnInteraction: false
        }}
      >
        {images.map((image, idx) => (
          <SwiperSlide key={idx} className='max-h-[600px]'>
            <BlurImage
              width={1000}
              height={600}
              src={`/${image}`}
              alt={`image-${image}`}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  )
}

export default Slide
