/* eslint-disable prettier/prettier */
import React, { FC } from 'react'
import CustomButton from './custom-button'

interface ICardProps {
    title: string,
    list: {
        id: number | string,
        description: string,
        icon: React.ReactNode,
        blocked?: boolean
    }[]
    buttonText: string,
    onClick: () => void
}

const Card: FC<ICardProps> = ({ list, onClick, title, buttonText }) => {
    return (
        <div className='flex flex-col px-6 py-8 gap-8 items-center border border-[#C121E1] bg-black/20 z-30 rounded-xl'>
            <h3 className='text-2xl text-[#C121E1] font-bold uppercase'>{title}</h3>
            <ul className='divide-y'>
                {list.map((item, index) => (
                    <li key={index} className='flex gap-2 py-2 border-white/20'>
                        <span>{item.icon}</span>
                        <span className={`font-light text-white ${item.blocked && 'line-through'}`}>{item.description}</span>
                    </li>
                ))}
            </ul>
            <CustomButton
                onClick={onClick}
                text={buttonText}
            />

        </div>
    )
}

export default Card