/* eslint-disable prettier/prettier */
import { Location, Calendar } from '../components'

export const mainBannerMoc = {
  nameEvent: {
    highlight: 'PV',
    text: 'Game Change'
  },
  mainTitle: {
    text: 'VENDA TODO DIA COM RECORRÊNCIA E MARGEM NO SEU NEGÓCIO DE VIAGENS!'
  },
  subTitle: {
    text: 'São + de 16h presenciais, para levar sua agência de viagens ou sua empresa de educação para o próximo dígito de lucro.'
  },
  warning: {
    text: '⚠️ Será o último Game Change Empreenda da história. Se eu fosse você, não perderia. ⚠️ '
  },
  infoList: [
    {
      id: 1,
      title: 'DATA',
      icon: <Calendar size={1.5} />,
      description: ['03 e 04 de Agosto/2024']
    },
    {
      id: 2,
      title: 'LOCAL',
      icon: <Location size={1.5} />,
      description: ['Espaço IBC', 'Av. Sarg. Lourival Alves de Souza, 153 - Jd Taquaral, São Paulo - SP']
    }
  ],
  linkText: {
    text: 'Quero Meu Ingresso!',
    href: '#'
  },
  videoId: 'XUx4UVSXIXE',
  playerId: '99583553-0c7c-40d5-b819-534dcd7867b9'
}
