/* eslint-disable prettier/prettier */
import React, { FC } from 'react'

interface IBoxProps extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode
    classesForBackground?: string
}

const Box: FC<IBoxProps> = ({
    children,
    className,
    classesForBackground,
    ...rest
}) => {
    return (
        <div className={`w-full ${classesForBackground}`}>
            <section className={`px-4 py-8 md:px-6 lg:px-0 w-full lg:w-[960px] xl:w-[1200px] m-auto ${className}`} 
            {...{ ...rest }}>{children}</section>
        </div>
    )
}

export default Box