/* eslint-disable prettier/prettier */
import React, { FC } from 'react'
import { IIconsProps } from '../../types/icons'

const CarLocation: FC<IIconsProps> = ({ fill = '#6419AE', size = 1 }) => {
    return (
        <svg height={16 * size} width={16 * size} version="1.1" id="_x32_"
            xmlns="http://www.w3.org/2000/svg"
            fill={fill}
            viewBox="0 0 512 512"
        >

            <g>
                <path className="st0" d="M174.468,203.704c-11.183,0-20.24,9.066-20.24,20.249c0,11.173,9.057,20.248,20.24,20.248
		c11.187,0,20.248-9.075,20.248-20.248C194.717,212.77,185.655,203.704,174.468,203.704z"/>
                <path className="st0" d="M405.969,62.122c-82.83-82.83-217.108-82.83-299.938,0c-82.813,82.822-82.813,217.12,0,299.924L255.994,512
		l149.976-149.953C488.781,279.243,488.781,144.944,405.969,62.122z M205.979,298.28c0,5.173-4.199,9.372-9.378,9.372h-25.897
		c-5.174,0-9.373-4.199-9.373-9.372v-19.292h44.648V298.28z M350.661,298.28c0,5.173-4.195,9.372-9.373,9.372H315.4
		c-5.187,0-9.382-4.199-9.382-9.372v-19.292h44.643V298.28z M337.519,271.003H174.468c-25.981,0-47.037-21.074-47.037-47.05
		c0-20.047,12.544-37.159,30.21-43.932l13.173-47.709c7.111-25.748,30.522-43.581,57.24-43.581h55.888
		c26.701,0,50.129,17.833,57.245,43.581l13.164,47.709c17.67,6.782,30.215,23.885,30.215,43.932
		C384.566,249.93,363.5,271.003,337.519,271.003z"/>
                <path className="st0" d="M308.433,137.1c-3.799-13.748-16.418-23.358-30.689-23.358h-43.493c-14.275,0-26.89,9.611-30.689,23.376
		l-4.792,17.358c-0.342,1.23-0.083,2.539,0.686,3.55c0.768,1.01,1.968,1.608,3.237,1.608h106.61c1.27,0,2.469-0.598,3.237-1.608
		c0.778-1.01,1.028-2.32,0.681-3.55L308.433,137.1z"/>
                <path className="st0" d="M337.519,203.704c-11.178,0-20.248,9.066-20.248,20.249c0,11.173,9.07,20.248,20.248,20.248
		c11.187,0,20.253-9.075,20.253-20.248C357.772,212.77,348.706,203.704,337.519,203.704z"/>
            </g>
        </svg>
    )
}

export default CarLocation