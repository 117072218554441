/* eslint-disable prettier/prettier */
import { FC } from 'react'
import { Blur, Box, Title } from '../../components'
import { IDepoimentsProps } from '../../types/home/depoiments'
import VTurb from '../../components/Player/VTub'
import { Container } from './styles'

const Depoiments: FC<IDepoimentsProps> = ({ title, videoIds }) => {
  return (
    <Box className='relative'>
      <Blur direction='left-bottom' />
      <Blur direction='right-top' />
      <Title text={title} />
      <Container>
        {videoIds.map((video, idx) => (
          <div className='box-glow overflow-hidden rounded-xl' key={idx}>
            <VTurb player={'99583553-0c7c-40d5-b819-534dcd7867b9'} video={video} iframe vertical />
          </div>
        ))}
      </Container>
    </Box>
  )
}

export default Depoiments
