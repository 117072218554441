/* eslint-disable prettier/prettier */

import { FC } from 'react'
import { Blur, Box, CustomLink, Title } from '../../components'
import VTurb from '../../components/Player/VTub'
import { ILastEventProps } from '../../types/home/last-event'
import { Container } from './styles'



const LastEvent: FC<ILastEventProps> = ({ title, videoId, playerId, buttonText }) => {
    return (
        <Box
            classesForBackground='bg-black'
            className='pt-16 pb-24 flex flex-col items-center justify-center relative'
        >
            <Blur direction='left-bottom' />
            <Blur direction='right-top' />
            <Title text={title} />
            <Container className='w-full rounded-xl overflow-hidden box-glow'>
                <VTurb
                    video={videoId}
                    player={playerId}
                />
            </Container>
            <CustomLink
                text={buttonText}
                className='w-full lg:w-auto mt-12'
            />
        </Box>
    )
}

export default LastEvent