/* eslint-disable prettier/prettier */
import { FC } from 'react'
import { Blur, Box, HotelsCard, Title } from '../components'
import { IHotelsProps } from '../types/home/hotels'

const Hotels: FC<IHotelsProps> = ({ title, hotels }) => {

  return (
    <Box
      className='relative'
    >
      <Title
        text={title}
      />
      <Blur direction='left-mid' />
      <Blur direction='right-bottom' />
      <div className='flex gap-4 flex-col flex-wrap items-center justify-center lg:flex-row'>
        {hotels.map(hotel => (
          <HotelsCard
            key={hotel.id}
            {...{ ...hotel }}
          />
        )
        )}
      </div>
    </Box>
  )
}

export default Hotels