import { atom } from 'jotai'
import { ICheckoutStore } from '../types/checkout'

export const checkoutDefaultValue: ICheckoutStore = {
  checkoutLink: '',
  page: '',
  value: 0,
  eventId: '',
  clint: 'https://andrinno.com/api/redirect/queue/182',
}

const checkoutDefaultAtom = atom<ICheckoutStore>(checkoutDefaultValue)

export const checkoutAtom = atom(
  (get) => get(checkoutDefaultAtom),
  (get, set, update: Partial<ICheckoutStore>) => {
    set(checkoutDefaultAtom, { ...get(checkoutDefaultAtom), ...update })
  }
)
