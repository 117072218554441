// import callix from '@/service/callix.service'
import fb from '@/service/fb.service'
import {checkoutAtom} from '@/store'
import {modalAtom} from '@/store/modal'
import {yupResolver} from '@hookform/resolvers/yup'
import {useAtom} from 'jotai'
import React from 'react'
import {SubmitHandler, useForm} from 'react-hook-form'
import {IMaskInput} from 'react-imask'
import * as yup from 'yup'
import {Backdrop, CloseButton, ModalTransactionWrapper} from './styles'

interface IFormInput {
    email: string
    name: string
    whatsapp: string
}

const schema = yup.object().shape({
    email: yup.string().email('Email inválido').required('Email é obrigatório'),
    name: yup.string().min(3, 'Digite o seu nome').required('Nome é obrigatório'),
    whatsapp: yup
        .string()
        .matches(/^\(\d{2}\) \d{5}-\d{4}$/, 'Celular inválido')
        .required('Celular é obrigatório')
})

const ModalForm: React.FC = () => {
    const [state, setState] = useAtom(modalAtom)
    const [checkoutState, setCheckoutState] = useAtom(checkoutAtom)
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: {errors}
    } = useForm<IFormInput>({
        resolver: yupResolver(schema)
    })

    const buttonDisabled = Object.keys(errors).length > 0

    interface IEmailAndWhats {
        email: string
        whatsapp: string
        name?: string
    }

    const sendDataToGtag = ({email, whatsapp}: IEmailAndWhats) => {
        // @ts-ignore
        if (typeof window !== 'undefined' && typeof gtag === 'function') {
            // @ts-ignore
            gtag('event', 'conversion', {
                send_to: 'AW-11344866062/4YJ1CM-BmIgZEI7W06Eq'
            })
            // @ts-ignore
            gtag('set', 'user_data', {
                email,
                phone_number: whatsapp
            })
        }
    }

    const redirectToCheckout = ({email, whatsapp, name}: IEmailAndWhats) => {
        if (process.env.NODE_ENV === 'production') {
            fb('Lead', 'Lead' + checkoutState.eventId, name, email, whatsapp)
            fb('InitiateCheckout', 'InitiateCheckout' + checkoutState.eventId, name, email, whatsapp)
            sendDataToGtag({email, whatsapp})
        }
        window.location.href = `${checkoutState.checkoutLink}?name=${name}&email=${email}&phone_number=${whatsapp}`
    }

    const onSubmit: SubmitHandler<IFormInput> = async ({email, name, whatsapp}) => {
        try {
            //CLINT
            if (process.env.NEXT_PUBLIC_CLINT_LEAD || checkoutState.clint) {
                const data = {
                    name,
                    email,
                    whatsapp,
                    pagina: checkoutState.page,
                    valor: checkoutState.value.toString(),
                }
                if (checkoutState.clint) {
                    await fetch(checkoutState.clint + '?' + new URLSearchParams(data))
                } else {
                    await fetch(
                        process.env.NEXT_PUBLIC_CLINT_LEAD +
                        '?' +
                        new URLSearchParams(data)
                    )
                }
            }

            //BREVO
            await fetch('/api/mailingList', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({name, email, whatsapp, pagina: checkoutState.page})
            })

            redirectToCheckout({email, whatsapp, name})
        } catch (error) {
            console.log(error)
        }
    }
    const handleCloseModal = () => {
        setState({open: false})
        reset()
    }

    if (!state.open) return null

    return (
        <>
            <Backdrop onClick={handleCloseModal}/>
            <ModalTransactionWrapper className='button-animation'>
                <div>
                    <CloseButton onClick={handleCloseModal}/>
                    <h2>Inscreva-se no {checkoutState.page}</h2>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <input type='text' {...register('name')} placeholder='Nome Completo'/>
                            {errors.name && <p>{errors.name.message}</p>}
                        </div>
                        <div>
                            <input type='email' {...register('email')} placeholder='E-mail'/>
                            {errors.email && <p>{errors.email.message}</p>}
                        </div>

                        <div>
                            <IMaskInput
                                mask='(00) 00000-0000'
                                inputRef={(el: HTMLInputElement | null) => register('whatsapp').ref(el)}
                                onAccept={(value: any) => setValue('whatsapp', value)}
                                placeholder='(XX) 9XXXX-XXXX'
                            />
                            {errors.whatsapp && <p>{errors.whatsapp.message}</p>}
                        </div>
                        <button type='submit' disabled={buttonDisabled}>
                            Continuar
                        </button>
                    </form>
                </div>
            </ModalTransactionWrapper>
        </>
    )
}

export default ModalForm
