/* eslint-disable prettier/prettier */
import React, { FC } from 'react'
import { BlurImage } from '.'
import { Star } from './icons'

interface HotelsCardProps {
  image: string
  stars: number
  title: string
  infos: {
    icon: React.ReactNode
    text: string
  }[]
}

const HotelsCard: FC<HotelsCardProps> = props => {
  const numberStars = Array.from({ length: props.stars }, (_, index) => index + 1)
  return (
    <div className='flex max-w-[288px] flex-col items-center lg:items-start px-6 py-8 gap-4 border border-[#C121E1] bg-black/20 z-30 rounded-xl hover:scale-[1.01] transition ease-in-out duration-300'>
      <div>
        <BlurImage src={props.image} width={500} height={500} alt={props.title} />
      </div>
      <div className='flex'>
        {numberStars.map(star => (
          <Star key={star} />
        ))}
      </div>
      <div className='flex justify-center'>
        <h3 className='text-white text-xl font-bold h-[56px] flex items-center text-center lg:text-left'>{props.title}</h3>
      </div>
      <ul className='flex flex-col gap-2'>
        {props.infos.map((info, index) => (
          <li key={index} className='flex gap-2'>
            <span>{info.icon}</span>
            <span className={`text-white text-sm ${index === 1 && 'h-[80px]'}`}>{info.text}</span>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default HotelsCard
